import { date, mixed } from 'yup'

/**
 * A string or number type schema to serve as our ID type
 * so that fields like organizationId which are ID type = string | number can match the right type
 *
 * once we switch to UUID ids and remove the number type from ID, we can revert to using strings only for all these fields
 */
export const identity = mixed<id>().test(
  'is-number-or-string',
  'Value must be a number or string',
  function (value) {
    return typeof value === 'number' || typeof value === 'string'
  },
)

// An ID field that can by null or undefined
export const optionalIdentity = mixed<id>().nullable().test(
  'is-number-or-string-or-undefined',
  'Value must be a number, string, null or undefined',
  function (value) {
    return typeof value === 'number' || typeof value === 'string' || !value
  },
)

// Image upload
const SIZE_LIMIT = 25000000 * 4

// TODO
// const { t } = useI18n({ useScope: 'global' })

export const imageUploadField = mixed<File | string>()
  .test(
    'fileSize',
    'Invalid image size (afbeelding te groot?)', // TODO, for example:
    // t('common.validations.invalidImageSize', {
    //   path: t('field.headerImage'),
    //   size: `${SIZE_LIMIT / 1000000}MB`,
    // }),
    (file) => {
      // If it's a URL (persisted file), we don't need to check the size
      if (file && file instanceof String && isUrl(file as string)) return
      // This is a custom validation function that will be called
      // before the form is submitted
      return !(file && file instanceof File && file.size > SIZE_LIMIT)
    },
  )
  .nullable()

// Yup's default date() schema has a weird way of handling dates, which leads to
// dates being posted -1 day from the actual date. This is caused by ISO date
// conversions and time zones. To fix this, we're converting all dates to UTC.
// Source: https://github.com/jquense/yup/issues/1092#issuecomment-1341986412
// Modified slightly to make the dayjs.isDayjs check work.
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const utcTransform = (_: unknown, value: unknown) => {
  if (
    typeof value === 'string'
    || typeof value === 'number'
    || value instanceof Date
    || dayjs.isDayjs(value)
  ) {
    const date = dayjs.utc(value).toDate()
    return date.toString() === 'Invalid Date' ? undefined : date
  }
  return undefined
}

// Use this utcDate schema instead of date() to make sure all dates are converted to UTC
export const utcDate = date().transform(utcTransform)
